import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { apiWoo, formatNumber, InvokeOrder } from "../../contants/_constants";
import moment from "moment";
import { Table } from "@mui/material";
import { InfoCard } from "./Cards";
import Grid from "@mui/material/Unstable_Grid2";
import { SearchInput } from "./SearchImput";
import { useSnackbar } from "notistack";
import InfiniteScroll from "react-infinite-scroll-component";
import { LoadingApp } from "../../Loading";
import { Delete, LocalGroceryStore } from "@mui/icons-material";
import AddressForm from "./AddressForm";

const drawerWidth = 350;
const navItems = [];

function Principal(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [products, setProducts] = useState([]);
  const [searchCategory, setSearchCategory] = useState(0);
  const [searchCategoryText, setSearchCategoryText] = useState();
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [openDialogAddress, setOpenDialogAddress] = useState(false);
  const [addressData, setAddressData] = useState({});

  const [searchText, setSearchText] = useState("");
  const [carrito, setCarrito] = useState([]);
  const [orden, setOrden] = useState({ total: 0, subTotal: 0, cantTotal: 0 });

  const fetchCategories = () => {
    const storageString = localStorage.getItem("CategoryList") || "";
    const cacheList =
      storageString.length !== 0
        ? JSON.parse(localStorage.getItem("CategoryList")) || []
        : [];
    if (cacheList?.length !== 0) setCategories(cacheList);
    else {
      apiWoo
        .get("products/categories", {
          // page: page,
          per_page: 100,
          // orderby: "id",
          // order: "asc",
          // status: "publish",
        })
        .then((response) => {
          if (response.status === 200) {
            //  console.log(response.data, "categories");
            setCategories(response.data);
            localStorage.setItem("CategoryList", JSON.stringify(response.data));
          }
        });
    }
  };
  const fetchProducts = () => {
    /*const storageString = localStorage.getItem("ProductsList") || "";
    const cacheList =
      storageString.length !== 0
        ? JSON.parse(localStorage.getItem("ProductsList")) || []
        : [];

    if (cacheList?.length !== 0 && page === 1) {
      setProducts(cacheList);
      //setHasNextPage(cacheList.length !== 0);
      //setPage((prev) => prev + 1);
    } else {*/
    if (page <= 0) {
      setPage(1);
      return;
    }
    apiWoo
      .get("products", {
        page: page,
        per_page: 50,
        orderby: "id",
        order: "asc",
        status: "publish",
      })
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data, "Products");
          //ubicar el _vendor_id
          console.log("Products", page, response.data.length, products.length);
          setProducts((prev) => [...prev, ...response.data]);

          setHasNextPage(response.data.length !== 0);
          setPage((prev) => prev + 1);

          /*localStorage.setItem(
              "ProductsList",
              JSON.stringify([...products, ...response.data])
            );*/
        }
      })
      .catch((error) => {
        console.log("fetchProducts Error", error.message);
      });
    // }
  };

  useEffect(() => {
    fetchCategories();
    fetchProducts();
    return () => {
      localStorage.clear();
    };
  }, []);

  const oldPage = useRef(1);
  useEffect(() => {
    if (hasNextPage && oldPage.current !== page) {
      oldPage.current = page;
      fetchProducts();
    }
  }, [hasNextPage, page]);

  const handleDrawerToggle = () => {
    if (orden?.subTotal > 0) setMobileOpen((prevState) => !prevState);
  };

  const getDataFiltro = useCallback(() => {
    let result = [];
    if (searchText && searchCategory === 0) {
      result =
        (products || []).filter((f) => {
          return f.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
        }) || [];
    } else if (searchText.length !== 0 && searchCategory > 0) {
      result =
        (products || []).filter((f) => {
          return (
            f.categories.some((s) => s.id === searchCategory) &&
            f.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
          );
        }) || [];
    } else if (searchText.length === 0 && searchCategory > 0) {
      result =
        (products || []).filter((f) => {
          return f.categories.some((s) => s.id === searchCategory);
        }) || [];
    } else result = products || [];

    if (result.length === 0)
      return (
        <Box style={{ width: "100%" }}>
          <h3>
            {` No Hay productos en ${
              searchCategoryText || ""
            }, cambie su busqueda.
              ${searchText || ""}`}
          </h3>
        </Box>
      );

    return result.map((p, index) => {
      return (
        <Grid key={`p${index}${p.id}`} xs={12} md={4} lg={3}>
          <InfoCard product={p} onAddToCart={onAddToCart} />
        </Grid>
      );
    });
  }, [searchText, searchCategory, products]);

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const onCheckOut = (dataForm) => {
    //setOpenDialogAddress(true);
    let list = [];
    if (carrito && dataForm?.email) {
      carrito.forEach((p) => {
        const price = p.price * 1;
        const subTotal = price * p.cant;
        let prod = {
          product_id: p.id,
          quantity: p.cant,
          subtotal: subTotal.toFixed(2),
          total: subTotal.toFixed(2),
          price: price.toFixed(2),
          meta_data: [
            {
              key: "Site",
              value: "wholesales",
            },
            {
              key: "_vendor_id",
              value: "",
            },
          ],
        };
        list.push(prod);
      });

      let dataSend = {
        set_paid: false,
        line_items: list,
        billing: dataForm,
        shipping: dataForm,
      };
      //console.log(dataSend);
      apiWoo
        .post("orders", dataSend)
        .then((response) => {
          let data = response.data;
          enqueueSnackbar(`Order: ${data.id} - Added Successfully..!!`, {
            vertical: "top",
            horizontal: "center",
            variant: "success",
          });

          //console.log(data);
          //_this.initialize();
          //_this.lastOrder = data; //order_key
          InvokeOrder(
            `https://nutriproyect.net/checkout/order-pay/${data.id}/?pay_for_order=true&key=${data.order_key}`
          );
        })
        .catch((error) => {
          console.log(error.message);
        });
    } else {
      enqueueSnackbar(`Email: ${dataForm?.email}`, {
        vertical: "top",
        horizontal: "center",
        variant: "warning",
      });
    }
  };

  const onAddToCart = (item, cantProd) => {
    //console.log("onAddToCart", item, cantProd);
    if (item?.id) {
      const existe = carrito.find((f) => f.id === item.id);
      if (existe) {
        cantProd = cantProd + existe.cant;
      }
      let list = carrito.filter((f) => f.id !== item.id);
      list = [
        {
          id: item.id,
          name: item.name,
          imageUrl: item.imageUrl,
          cant: cantProd,
          price: item.price,
          permalink: item.permalink,
          subTotal: cantProd * item.price,
        },
        ...(list || []),
      ];

      const order = { ...orden };
      list.forEach((o) => {
        order.cantTotal += o.cant;
        order.subTotal += o.subTotal;
      });
      setOrden(order);
      setCarrito(list);

      enqueueSnackbar(`${item.name} - Added Successfully..!!`, {
        vertical: "top",
        horizontal: "center",
        variant: "success",
      });
      enqueueSnackbar(`SubTotal: $${formatNumber(order.subTotal)}`, {
        vertical: "top",
        horizontal: "center",
        variant: "info",
      });
    }
  };

  const onRemoveCart = (item) => {
    //console.log("onRemoveCard", item);
    if (item?.id) {
      let list = carrito.filter((f) => f.id !== item.id);

      const order = { cantTotal: 0, subTotal: 0 };
      list.forEach((o) => {
        order.cantTotal += o.cant;
        order.subTotal += o.subTotal;
      });
      setOrden(order);
      setCarrito(list);

      enqueueSnackbar(`${item.name} - Remove Successfully..!!`, {
        vertical: "top",
        horizontal: "center",
        variant: "warning",
      });
      enqueueSnackbar(`SubTotal: $${formatNumber(order.subTotal)}`, {
        vertical: "top",
        horizontal: "center",
        variant: "info",
      });
    }
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <a href="https://nutriproyect.net/">
        <img
          src="https://nutriproyect.net/wp-content/uploads/2020/09/NutriProyect-Logo2Small1.png"
          className="logo_fixed"
          alt="img"
          width="200"
          height="39&"
        />
      </a>

      <Typography
        variant="h6"
        component="div"
        sx={{
          flexGrow: 1,
          display: { xs: "none", md: "block", sm: "none" },
        }}
      >
        ${formatNumber(orden?.subTotal)}
      </Typography>
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          setOpenDialogAddress(true);
        }}
      >
        Checkout
      </Button>
      <Divider />
      <List>
        {carrito?.map((item) => (
          <ListItem
            key={`LTI${item.id}`}
            disablePadding
            style={{ color: "black" }}
          >
            <ListItemButton
              sx={{ textAlign: "center" }}
              style={{
                color: "black",
                textAlign: "left",
                fontSize: "0.85rem",
                fontWeight: "400",
              }}
            >
              <img style={{ width: "50px" }} src={item.imageUrl} />
              <div
                style={{
                  display: "flex",
                  alignContent: "center",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <span>{item.name}</span>

                <span>{`${item.cant} x $${formatNumber(item.subTotal)}`}</span>
              </div>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  onRemoveCart(item);
                }}
              >
                <Delete />
              </Button>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const _renderCategory = () => {
    return (categories || []).map((m) => {
      return (
        <Button
          color={m.name === searchCategoryText ? "info" : "primary"}
          variant={m.name === searchCategoryText ? "outlined" : "text"}
          onClick={() => {
            setSearchCategoryText(m.name);
            if (m.id === 176) setSearchCategory(0);
            else setSearchCategory(m.id);
          }}
          style={{ textTransform: "none" }}
        >
          {`${m.name.replace("&amp;", "&")} (${m.count})`}
        </Button>
      );
    });
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: { xs: "none", md: "block", sm: "none" } }}>
            <a href="https://nutriproyect.net/">
              <img
                src="https://nutriproyect.net/wp-content/uploads/2020/09/NutriProyect-Logo2Small1.png"
                className="logo_fixed"
                alt="img"
                width="200"
                height="39&"
              />
            </a>
          </Box>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "block", sm: "none" },
            }}
          >
            NutriProyect - Wholesale
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SearchInput
              onChange={(e) => {
                //console.log(e.currentTarget.value);
                const val = e.currentTarget.value;
                setSearchText(val);
              }}
            />

            <Button
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                margin: "5px",
                textAlign: "right",
                color: "white",
              }}
              onClick={handleDrawerToggle}
            >
              <LocalGroceryStore />
              {orden?.cantTotal} Item ${formatNumber(orden.subTotal)}
            </Button>
            {navItems.map((item) => (
              <Button key={item} sx={{ color: "#fff" }}>
                {item}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          anchor="right"
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            gap: "3px",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "none",
          }}
        >
          {_renderCategory()}
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <InfiniteScroll
            dataLength={products ? products.length : 0}
            next={fetchProducts}
            hasMore={!!hasNextPage}
            loader={<h4></h4>}
            style={{ overflow: "hidden" }}
          >
            <Grid container spacing={2}>
              {getDataFiltro()}
            </Grid>
          </InfiniteScroll>
        </Box>
      </Box>

      <AddressForm
        dataForm={addressData}
        open={openDialogAddress}
        onClose={(data) => {
          setOpenDialogAddress(false);
          setAddressData(data);
        }}
        onSetDataAdress={(data) => {
          onCheckOut(data);
          setOpenDialogAddress(false);
          setAddressData(data);
        }}
      />
    </Box>
  );
}

Principal.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Principal;
